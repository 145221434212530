import React from 'react'
import Nav from '../../components/Nav'
import { useParams, Navigate, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db } from '../../firebase/init';
import { doc, getDoc } from "firebase/firestore";
import Loading from '../../components/Loading';
import BronzeBadge from '../../assets/BronzeBadge.svg';
import SilverBadge from '../../assets/SilverBadge.svg';
import GoldBadge from '../../assets/GoldBadge.svg';
import Prizes from '../../components/Prizes';
import RankingPeriod from '../../components/RankingPeriod';

export default function Ranking() {
    // Get the matched parameters from the URL
    const { teamId } = useParams();
    const [teamInfo, setTeamInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState(null);
    const [update, setUpdate] = useState(false);


    useEffect(() => {
        // Function to fetch team data using the 'teamId'
        const fetchTeamData = async () => {
            try {
                const docRef = doc(db, "teams", teamId);
                const teamData = await getDoc(docRef);
                if (teamData.exists()) {
                    setTeamInfo(teamData.data());
                    // set the document title to the team name
                    document.title = `🏆 ${teamData.data().name} | collab hour`;
                    setMembers(teamData.data().members);
                }
                setLoading(false);
            } catch (error) {

                Navigate('/'); // Redirect to home page if team data is not found
            }
        };

        // Call the function to fetch team data
        fetchTeamData();
        setUpdate(false);
    }, [teamId, update]); // Add 'teamId' as a dependency, so the effect runs whenever 'teamId' changes


    // Function to sort the members based on XP in descending order
    const sortMembersByXP = (members) => {
        return [...members].sort((a, b) => b.XP - a.XP);
    };

    // useEffect hook to sort members whenever the state changes
    useEffect(() => {
        if (members) {
            const sortedMembers = sortMembersByXP(members);
            // Check if the sortedMembers are different from the current state before setting them
            if (JSON.stringify(sortedMembers) !== JSON.stringify(members)) {
                setMembers(sortedMembers);
            }
        }
    }, [members]);

    return (
        <div>
            <Nav />
            {loading && <Loading />}
            <div className='px-6 py-10'>
                <div className="text-sm breadcrumbs">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        {teamInfo && <li><Link to={`/team/${teamId}`}>{teamInfo.name}</Link></li>}
                        {teamInfo && <li>Ranking</li>}
                    </ul>
                </div>
                <h1 className='text-3xl font-bold'>🏆 Ranking</h1>
                {teamInfo &&
                    <div className='flex items-center mt-3'>
                        <Prizes teamData={teamInfo} teamId={teamId} handleUpdate={setUpdate} />
                        <RankingPeriod teamData={teamInfo} teamId={teamId} handleUpdate={setUpdate} />
                    </div>
                }
                {/* Table Ranking */}
                <div className="overflow-x-auto">
                    <table className="table w-full">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Member</th>
                                <th>XP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members && members.map((member, index) => {
                                return (
                                    < tr key={index}>
                                        <td className='flex items-center'>
                                            <div
                                                className={`${index <= 2 && "w-10 h-10 "} flex items-center justify-center w-10`}
                                                style={{
                                                    backgroundImage: `url(${index === 0
                                                        ? GoldBadge
                                                        : index === 1
                                                            ? SilverBadge
                                                            : index === 2
                                                                ? BronzeBadge
                                                                : ''
                                                        })`,
                                                    backgroundSize: 'contain',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                }}
                                            >
                                                <p className={`${index <= 2 ? "text-white mb-1 mr-0.5" : index <= 4 ? "text-blue-500" : "text-base-content"} font-bold text-lg`}>{index + 1}</p>
                                            </div>


                                        </td>
                                        <td>
                                            <div className='flex items-center'>
                                                < img src={member.photoURL} className='w-8 h-8 object-cover inline-block rounded-md mr-5' />
                                                <span>{member.user}</span>
                                            </div>
                                        </td>
                                        <td>{member.XP}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div >
            </div >
        </div >
    )
}
