import { Link, Navigate, useParams } from 'react-router-dom';
import Nav from '../../components/Nav';
import { db } from '../../firebase/init';
import { useEffect, useState } from 'react'
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import statsChart from '../../assets/stats-chart.svg';
import addCircle from '../../assets/add-circle-outline.svg';
import TeamDetails from '../../components/TeamDetails';
import Loading from '../../components/Loading';
import JoinCode from '../../components/JoinCode';
import TaskDetails from '../../components/TaskDetails';
import ChallengeMembers from '../../components/ChallengeMembers';




export default function TeamMain() {
    // Get the matched parameters from the URL
    const { teamId } = useParams();
    const [teamInfo, setTeamInfo] = useState(null);

    // needed for the task creation modal
    const [checked, setChecked] = useState(true);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [priority, setPriority] = useState('low');
    const [xp, setXp] = useState(0);
    const [createTask, setCreateTask] = useState(false);
    const [taskMode, setTaskMode] = useState('');
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [radioStatus, setRadioStatus] = useState('open');
    const [loading, setLoading] = useState(true);
    const [userSetXP, setUserSetXP] = useState(false);

    // needed for display details of a task
    const [taskDetails, setTaskDetails] = useState(null);
    const [taskIndex, setTaskIndex] = useState(null);


    const handleOptionChange = () => {
        setChecked(false);
    };

    useEffect(() => {
        // Function to fetch team data using the 'teamId'
        setLoading(true);
        const fetchTeamData = async () => {
            try {
                const docRef = doc(db, "teams", teamId);
                const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        setTeamInfo(docSnapshot.data());
                        // set the document title to the team name
                        document.title = `${docSnapshot.data().name} | collab hour`;
                    } else {
                        // Redirect to home page if team data is not found
                        Navigate('/');
                    }
                    setLoading(false);
                });

                // Clean up the listener when the component unmounts or 'teamId' changes
                return () => unsubscribe();
            } catch (error) {
                // Redirect to home page if there's an error fetching team data
                Navigate('/');
            }
        };

        // Call the function to fetch team data
        fetchTeamData();
    }, [teamId, submitButtonClicked]);

    //check if the task can be submitted
    useEffect(
        () => {
            if (name && hours || minutes) {
                setCreateTask(true);
                if (!description) {
                    setDescription('No description provided');
                }
                if (!userSetXP) {
                    setXp(parseInt(hours) * 60 + parseInt(minutes));
                }
            } else {
                setCreateTask(false);
            }
        },
        [name, hours, minutes, priority, xp, description]
    )

    const createNewTask = async () => {
        if (submitButtonClicked) {
            setSubmitButtonClicked(false);
            const task = {
                name: name,
                description: description,
                timeInvestment: `${hours}h, ${minutes}min`,
                priority: priority,
                xp: xp,
                taskMode: taskMode,
                status: 'open'
            }

            if (taskMode === "challenge") {
                console.log("hello Ben");
                task.didChallenge = [];
                task.doesChallenge = [];
            }
            const docRef = doc(db, "teams", teamId);
            // add this task to the team's tasks array
            if (teamInfo.tasks) {
                await updateDoc(docRef, { tasks: [...teamInfo.tasks, task] });
                setSubmitButtonClicked(true);
                setSubmitButtonClicked(false);
                setSubmitButtonClicked(false);
            } else {
                await updateDoc(docRef, { tasks: [task] });
                setSubmitButtonClicked(true);
                setSubmitButtonClicked(false);
                setSubmitButtonClicked(false);
            }
            // clear the form
            setName('');
            setDescription('');
            setPriority('low');
            setXp(0);
            setSubmitButtonClicked(true);
            setSubmitButtonClicked(false);
            setSubmitButtonClicked(false);
            setUserSetXP(false);

        } else {
            return;
        }

    }

    return (
        <div>
            <Nav />
            {loading && <Loading />}
            <div className='px-6 py-10'>
                <div className="text-sm breadcrumbs">
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        {teamInfo && <li>{teamInfo.name}</li>}

                    </ul>
                </div>
                {teamInfo && (<div>
                    <h1 className='font-bold text-2xl mb-5'>{teamInfo.name}</h1>
                    <div className='flex items-center'>
                        <Link className='btn btn-primary flex justify-center items-center' to={`/team/${teamId}/ranking`}>
                            <img src={statsChart} alt="stats chart" className='w-5 h-5 md:mr-2' />
                            <span className='hidden md:block'>Ranking</span>
                        </Link>
                        <JoinCode joincode={teamId} />
                        <TeamDetails TeamDetails={teamInfo} />
                    </div>
                    <h2 className='mt-5 font-bold text-xl mb-3'>Tasks</h2>
                    <div className='flex items-center'>
                        <div className="join">
                            <input className="join-item btn" type="radio" name="options" aria-label="Open"
                                defaultChecked={checked} onChange={() => {
                                    handleOptionChange();
                                    setRadioStatus('open');
                                }} />
                            <input className="join-item btn" type="radio" name="options" aria-label="Work in Progress"
                                onChange={() => {
                                    handleOptionChange();
                                    setRadioStatus('wip');
                                }} />
                            <input className="join-item btn" type="radio" name="options"
                                aria-label="Finished" onChange={() => {
                                    handleOptionChange();
                                    setRadioStatus('finished');
                                }} />

                        </div>

                        <div className="dropdown dropdown-hover md:block hidden">
                            <label tabIndex={0} className="btn btn-neutral ml-4">
                                <img src={addCircle} className="h-6 w-6" alt='graphic' />

                                Add new task
                            </label>
                            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 ml-3">
                                <li onClick={() => {
                                    window.my_modal_3.showModal()
                                    setTaskMode('ticket')
                                }}><a>Ticket</a></li>
                                <li onClick={() => {
                                    setTaskMode('challenge')
                                    window.my_modal_3.showModal()
                                }}><a>Challenge</a></li>
                            </ul>
                        </div>
                        <details className="dropdown dropdown-hover dropdown-top dropdown-end md:hidden fixed bottom-4 right-4 z-50">
                            <summary tabIndex={0} className="btn btn-neutral">
                                <img src={addCircle} className="h-6 w-6" alt='graphic' />
                                Add task
                            </summary>
                            <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52 mb-1">
                                <li onClick={() => {
                                    window.my_modal_3.showModal()
                                    setTaskMode('ticket')
                                }}><a>Ticket</a></li>
                                <li onClick={() => {
                                    setTaskMode('challenge')
                                    window.my_modal_3.showModal()
                                }}><a>Challenge</a></li>
                            </ul>
                        </details>

                        <dialog id="my_modal_3" className="modal">
                            <form method="dialog" className="modal-box" onSubmit={
                                () => createNewTask()
                            }>
                                <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                <h3 className="font-bold text-lg">Create new {taskMode}</h3>
                                <div>
                                    <label className="label">
                                        <span className="label-text">Name</span>
                                        <span className="indicator-item badge">Required</span>
                                    </label>

                                    <input
                                        type="text"
                                        placeholder="e.g. software engineering"
                                        className="input input-bordered mb-2 w-full"
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                    />
                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">Description</span>
                                    </label>
                                    <textarea placeholder="e.g. building the future of project management 🚀 "
                                        className="textarea textarea-bordered w-full mr-0 mb-2"
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                    >
                                    </textarea>

                                </div>
                                <div className="form-control w-full">
                                    <label className="label">
                                        <span className="label-text">Priority</span>
                                    </label>
                                    <select className="select select-bordered" value={priority} onChange={(e) => setPriority(e.target.value)}>
                                        <option value="low">low</option>
                                        <option value="medium">medium</option>
                                        <option value="high">high</option>
                                    </select>
                                </div>
                                <div className='flex items-center justify-between'>
                                    <p className='text-sm ml-1 mt-4 mb-2'>Estimated time investment</p>
                                    <span className="indicator-item badge mt-4 mb-2">Required</span>
                                </div>
                                <div className='flex'>
                                    <input type="number" placeholder="hours" className="input input-bordered w-full mr-1" onChange={
                                        (e) => setHours(e.target.value)
                                    } />
                                    <input type="number" placeholder="minutes" className="input input-bordered w-full ml-1" onChange={
                                        (e) => setMinutes(e.target.value)
                                    } />
                                </div>
                                <div>
                                    <label className="label">
                                        <span className="label-text">XP (Experience Points)</span>
                                    </label>
                                    <input
                                        type="number"
                                        placeholder={(hours ? parseInt(hours) : 0) * 60 + parseInt(minutes)}
                                        className="input input-bordered mb-2 w-full"
                                        onChange={(e) => {
                                            setXp(e.target.value)
                                            setUserSetXP(true);
                                        }}
                                    />
                                </div>
                                <button type='submit' className={`btn  btn-block mt-5 ${!createTask ? "btn-disabled" : "btn-primary"}`}
                                    onClick={
                                        () => setSubmitButtonClicked(true)
                                    }>Create</button>
                            </form>
                        </dialog>

                    </div>

                    <TaskDetails taskDetails={taskDetails} teamId={teamId} teamInfo={teamInfo} index={taskIndex} />

                    {teamInfo.tasks && (
                        <div className="overflow-x-auto">
                            <table className="table mt-9">
                                {/* head */}
                                <thead>
                                    <tr>
                                        <th className='hidden md:block'>Status</th>
                                        <th>Name</th>
                                        <th>XP</th>
                                        <th className='md:block hidden'>🕙 Est. time investment</th>
                                        <th className='md:hidden block'>🕙 Est. time</th>
                                        <th>Priority</th>
                                        <th>Type</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    {teamInfo.tasks.map((task, index) => {
                                        if (task.status === radioStatus) {
                                            return (
                                                <tr className="hover:bg-base-200" key={index}
                                                    onClick={() => {
                                                        // show modal
                                                        setTaskDetails(task)
                                                        setTaskIndex(index)
                                                        window.my_modal_99.showModal()
                                                    }}
                                                >
                                                    <td className='hidden md:block'>{task.status}</td>
                                                    <td>{task.name}</td>
                                                    <td>{task.xp}</td>
                                                    <td>{task.timeInvestment}</td>
                                                    <td className={task.priority === "high" ? "bg-red-400 text-white" : task.priority === "medium" ? "bg-yellow-400 text-white" : "bg-green-400 text-white"}>{task.priority}</td>
                                                    <td>{task.taskMode === "challenge" ? "🎯" : "📌"}{"    "}{task.taskMode}</td>

                                                </tr>
                                            );
                                        }
                                        return null; // Include this to handle other cases and avoid a React warning for not returning any elements
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {!teamInfo.tasks &&
                        (
                            <div className="mt-5">
                                <h2>No tasks yet</h2>
                            </div>)
                    }
                </div>)}

            </div>
        </div >
    )
}