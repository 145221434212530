// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBEDVHy91ahzx1qstOxIzaRAx-QIFOkm04",
    authDomain: "powerhour-teams.firebaseapp.com",
    projectId: "powerhour-teams",
    storageBucket: "powerhour-teams.appspot.com",
    messagingSenderId: "186557006343",
    appId: "1:186557006343:web:3e65007d47ab0cbee820a2",
    measurementId: "G-E1YHQMYL6R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then((result) => {
        let displayName = result.user.displayName;
        let email = result.user.email;
        let photoUrl = result.user.photoURL;

        localStorage.setItem('displayName', displayName);
        localStorage.setItem('email', email);
        localStorage.setItem('photoUrl', photoUrl);

        // refresh site
        window.location.reload();

        console.log(result);
    }).catch((error) => {
        console.log(error);
    });
}

export const logout = () => {
    signOut(auth)
        .then(() => {
            // clear local storage
            localStorage.clear();

            // refresh site
            window.location.reload();

        })
        .catch((error) => {
            console.log(error);
        });
}