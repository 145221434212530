import React from 'react'
import { useState, useEffect } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import { db } from '../firebase/init';
import { doc, updateDoc } from "firebase/firestore";
import GoldBadge from '../assets/GoldBadge.svg';
import SilverBadge from '../assets/SilverBadge.svg';
import BronzeBadge from '../assets/BronzeBadge.svg';

export default function Prizes({ teamData, teamId, handleUpdate }) {
    const email = localStorage.getItem('email');
    const [addForm, setAddForm] = useState(false);
    const [newPrize, setNewPrize] = useState({ prize: '', rank: '' });
    const [prizes, setPrizes] = useState([]);

    useEffect(() => {
        if (teamData.prizes) {
            const newPrizes = teamData.prizes.sort((a, b) => a.rank - b.rank);
            setPrizes(newPrizes);
        }
    }, [teamData.prizes])

    const docRef = doc(db, "teams", teamId);

    return (
        <div className='mr-2'>
            <Toaster className="z-50" />
            <button className="btn" onClick={() => window.my_modal_123.showModal()}>🎁  Prizes</button>
            <dialog id="my_modal_123" className="modal">
                <form method="dialog" className="modal-box">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    <h3 className="font-bold text-lg mb-5">Prizes</h3>
                    {!teamData.prizes && <p className='text-base-content mb-5 text-center'>No prizes yet</p>}
                    {prizes && prizes.map((prize, index) => {
                        return (
                            <div className='flex items-center mb-5' key={index}>
                                <div
                                    className={`${index <= 2 && "w-10 h-10 "} flex items-center justify-center w-10 mr-5`}
                                    style={{
                                        backgroundImage: `url(${index === 0
                                            ? GoldBadge
                                            : index === 1
                                                ? SilverBadge
                                                : index === 2
                                                    ? BronzeBadge
                                                    : ''
                                            })`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                >
                                    <p className={`${index <= 2 ? "text-white mb-1 mr-0.5" : index <= 4 ? "text-blue-500" : "text-base-content"} font-bold text-lg`}>{prize.rank}</p>
                                </div>

                                <p className='mr-2'>{prize.prize}</p>
                            </div>
                        )
                    })}

                    {addForm &&
                        <div className='mb-5'>
                            <div className="divider"></div>
                            <label className="label">
                                <span className="label-text">Add prize</span>
                            </label>
                            <div className='flex items-center'>
                                <input type="text" placeholder="Prize" className="input input-bordered   w-9/12 mr-2"
                                    onChange={(event) => {
                                        setNewPrize({ ...newPrize, prize: event.target.value })

                                    }} />
                                <input type="number" placeholder="Rank" className="input input-bordered w-3/12"
                                    onChange={(event) => {
                                        setNewPrize({ ...newPrize, rank: event.target.value })

                                    }}
                                />
                            </div>
                        </div>
                    }


                    {teamData.admin === email &&
                        <button className={`btn btn-block ${addForm == true && "btn-primary"}`} type='button'
                            onClick={async () => {
                                handleUpdate(true);
                                // if addForm == false -> set to true
                                if (addForm === false) {
                                    setAddForm(true);
                                }
                                // if addForm == true -> set to false and add new prize
                                else {
                                    if (newPrize.prize.length > 0 && newPrize.rank.length > 0) {
                                        setAddForm(false);
                                        if (teamData.prizes) {
                                            await updateDoc(docRef, {
                                                prizes: [...teamData.prizes, newPrize],
                                            })
                                            handleUpdate(true);
                                        }
                                        else {
                                            await updateDoc(docRef, {
                                                prizes: [newPrize],
                                            })
                                            handleUpdate(true);
                                        }
                                    } else {
                                        if (newPrize.prize.length === 0) {
                                            toast.error("Please enter a prize name")
                                        } else {
                                            toast.error("Please enter a rank")
                                        }
                                    }
                                }
                            }
                            }>+ Add prize</button>
                    }
                </form>
            </dialog>
        </div>
    )
}
