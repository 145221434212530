import React from 'react'
import Nav from '../components/Nav'

export default function Home() {
    const displayName = localStorage.getItem('displayName');
    const profilePic = localStorage.getItem('photoUrl');
    console.log(profilePic);
    const email = localStorage.getItem('email');
    return (
        <div>
            <Nav />
            <div className='px-6 py-10'>
                <div className='flex items-center'>
                    <img src={profilePic} className='rounded-lg w-20 h-20' />
                    <div className='ml-5'>
                        <h1 className='font-bold text-3xl'>{`${displayName}`}</h1>
                        <h2>{email}</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}