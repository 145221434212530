import React from 'react'
import rankingPeriodsShowcase from '../assets/rankingPeriodsShowcase.png';

export default function RankingPeriod({ teamData, teamId, handleUpdate }) {
    return (
        <div>
            <button className='btn' onClick={() => {
                if (teamData.rankingPeriod) {
                    window.my_modal_122.showModal()
                } else {
                    window.my_modal_121.showModal()
                }
            }}> ⏳ Ranking period</button>
            <dialog id="my_modal_121" className="modal">
                <form method="dialog" className="modal-box">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    <h1 className='font-bold text-2xl'>About ranking periods</h1>
                    <h2 className='text-gray-400 font-bold'>Cooming soon</h2>
                    <p>Define sprint durations and watch as top performers are automatically awarded at the end of each sprint. Stay engaged as the competition renews itself, bringing new opportunities for success! </p>
                    <img src={rankingPeriodsShowcase} className='mt-5' />
                    <button className='btn btn-primary btn-block mt-5'>Close</button>
                </form>
            </dialog>
            <dialog id="my_modal_122" className="modal">
                <form method="dialog" className="modal-box">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                </form>
            </dialog>
        </div>
    )
}
