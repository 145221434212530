import React from 'react'
import logoCollabhour from '../assets/logoCollabhour.png';

export default function LandingFooter() {
    return (
        <footer className="footer p-10 bg-neutral text-neutral-content">
            <div>
                <img className="w-12" src={logoCollabhour} />
                <p>collabhour by <a href="https://getpowerhour.com" className='underline'>powerhour</a></p>
            </div>
        </footer>
    )
}
