import React from 'react'
import logIn from '../assets/log-in-outline.svg';
import { Toaster, toast } from 'react-hot-toast';
import clipboard from "../assets/clipboard.svg"


export default function JoinCode({ joincode }) {

    return (
        <div className="">
            <Toaster />
            <button className="btn btn-secondary ml-2" onClick={() => window.my_modal_1.showModal()}>
                <img src={logIn} className='w-5 h-5 mr-1' />
                <span className='hidden md:block'>Invite Members</span>
            </button>
            <dialog id="my_modal_1" className="modal">
                <form method="dialog" className="modal-box">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    <h3 className="font-bold text-lg mb-3">Invite Members</h3>
                    <h5 className='font-semibold text-md'>Share this code to invite new members: </h5>
                    <h1 className='border-2 border-solid border-base-300 p-4 mt-3 rounded-md
                     bg-base-100 hover:bg-base-200 transition-all flex justify-between' onClick={() => {
                            toast.success("copied to clipboard")
                            navigator.clipboard.writeText(joincode)
                        }
                        }>{joincode}
                        <img src={clipboard} className='w-6 h-6' />
                    </h1>
                    <button className='btn btn-block btn-primary mt-5'>Close</button>
                </form>
            </dialog>
        </div>
    )
}
