import React from 'react'
import { signInWithGoogle } from '../firebase/init'

export default function Login() {
    return (
        <div className='md:grid grid-cols-2'>
            <div className='bg-gradient-to-br from-indigo-600 via-pink-500 to-purple-600 hidden md:flex justify-center items-center'>
                <div className='bg-white rounded-md opacity-50 px-10 py-40 h-min backdrop-blur-lg'>
                    <h1 className='text-5xl font-bold'>Turning your</h1>
                    <h1 className='text-5xl font-bold'>team projects</h1>
                    <h1 className='text-5xl font-bold'>into a fun</h1>
                    <h1 className='text-5xl font-bold'><span className='text-purple-600'>game</span></h1>
                </div>
            </div>

            <div className='flex flex-wrap items-center h-screen justify-center'>
                <div>
                    <h1 className='w-full font-bold text-4xl text-center'>Hey there, so nice to see you 👋</h1>
                    <h2 className="mb-12 mt-1 text-gray-500 font-semibold text-center">Sign up or login</h2>
                    <div className='flex justify-center'>
                        <button
                            className="px-6 py-3 border flex gap-2 border-base-300 rounded-lg text-content bg-base-100
                             hover:border-blue-300 hover:text-content hover:shadow transition duration-150 text-center hover:bg-base-200"
                            onClick={() => signInWithGoogle()}
                        >

                            <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />
                            <span>Continue with Google</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
