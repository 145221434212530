import React from 'react'

export default function ChallengeMembers({ taskInfo, teamInfo }) {
    let members;
    let profilePics;

    console.log(teamInfo)

    if (taskInfo.taskMode == "challenge") {
        members = taskInfo.doesChallenge.concat(taskInfo.didChallenge);
        // search the profilePics in member objects in teamInfo
        profilePics = members.map((member) => {
            const teamMember = teamInfo.members.find((teamMember) => teamMember.user === member);
            return teamMember ? teamMember.photoURL : "/blank-profile-picture.png";
        });
    }

    return (
        <div>
            <h1 className='mb-2 font-bold'>Particpants:</h1>
            <div className="avatar-group -space-x-6 mb-5">
                {members &&
                    members.map((member, index) => {
                        if (index < 2) {
                            return (
                                <div className="avatar border-gray-300 border" key={index}>
                                    <div className="w-8 h-8">
                                        <img src={profilePics[index]} alt={`Member ${index + 1}`} />
                                    </div>
                                </div>
                            );
                        } else if (index === 2) {
                            return (
                                <div className="avatar placeholder border-gray-300 border" key={index}>
                                    <div className="w-8 h-8 bg-neutral-focus text-neutral-content">
                                        <span>+{members.length - 2}</span>
                                    </div>
                                </div>
                            );
                        } else {
                            return null; // Render nothing for the rest of the members
                        }
                    })}
                {members.length == 0 && <p className='text-gray-500'>No participants yet</p>}

            </div>
        </div>
    )
}
