import React, { useEffect, useState } from 'react'

import alarm from '../assets/alarm.svg'
import { toast, Toaster } from 'react-hot-toast';
import { db } from '../firebase/init';
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import ChallengeMembers from './ChallengeMembers';

export default function TaskDetails({ taskDetails, teamId, teamInfo, index }) {
    const [userId, setUserId] = useState('');
    const [userInfo, setUserInfo] = useState(null);
    const [update, setUpdate] = useState(false);

    const email = localStorage.getItem('email');



    const addToMyTasks = async (taskDetails) => {
        if (!teamInfo.company) {
            teamInfo.company = '-';
        }

        const newTask = {
            name: taskDetails.name,
            teamName: teamInfo.name,
            company: teamInfo.company,
            teamsID: teamId,
            status: "wip"
        }

        // add the new task to the myTasks array in the user's document
        const userRef = doc(db, "userInfo", userId);

        const taskExists = userInfo.myTasks.some((task) => {
            // Assuming newTask is considered equal to an existing task if all its properties match
            return (
                task.name === newTask.name &&
                task.company === newTask.company &&
                task.teamsID === newTask.teamsID
            );
        });

        if (!taskExists) {
            await updateDoc(userRef, {
                myTasks: [...userInfo.myTasks, newTask],
            });

            toast.success('Task added to your tasks!');
            setUpdate(true);

            if (index !== undefined && index >= 0) {
                const docRef = doc(db, "teams", teamId);
                const updatedTasks = [...teamInfo.tasks];
                updatedTasks[index].status = 'wip';



                await updateDoc(docRef, {
                    tasks: updatedTasks,
                });
                toast.success('Task status changed to work in progress', { duration: 4000, });
            }


        } else {
            toast.success(`"${taskDetails.name}" already exists in your tasks!`);
        }

    }


    const markAsFinished = async (taskDetails) => {
        if (!teamInfo.company) {
            teamInfo.company = '-';
        }

        const newTask = {
            name: taskDetails.name,
            teamName: teamInfo.name,
            company: teamInfo.company,
            teamsID: teamId,

        }

        // add the new task to the myTasks array in the user's document
        const userRef = doc(db, "userInfo", userId);

        const taskExists = userInfo.myTasks.findIndex((task) => {
            // Assuming newTask is considered equal to an existing task if all its properties match
            return (
                task.name === newTask.name &&
                task.company === newTask.company &&
                task.teamsID === newTask.teamsID
            );
        });


        if (taskExists === -1) {
            await updateDoc(userRef, {
                myTasks: [...userInfo.myTasks, newTask],
            });
            setUpdate(true);
            toast.success('Task added to your tasks');

            if (index !== undefined && index >= 0) {
                const docRef = doc(db, "teams", teamId);
                const updatedTasks = [...teamInfo.tasks];
                updatedTasks[index].status = 'finished';
                await updateDoc(docRef, {
                    tasks: updatedTasks,
                });
            }
            toast.success(`${taskDetails.name} marked as finished`);
            // user gets XP   

            const docRef = doc(db, "teams", teamId);
            const updatedMembers = [...teamInfo.members];
            const memberIndex = updatedMembers.findIndex((member) => member.user === email);
            console.log(updatedMembers, memberIndex);
            updatedMembers[memberIndex].XP += 10;

            await updateDoc(docRef, {
                members: updatedMembers,
            });
        } else {
            // update the status 


            const newMyTasks = [...userInfo.myTasks];
            newMyTasks[taskExists].status = 'finished';
            setUpdate(true);
            await updateDoc(userRef, {
                myTasks: newMyTasks,
            });

            // find the index
            if (index !== undefined && index >= 0) {
                const docRef = doc(db, "teams", teamId);
                const updatedTasks = [...teamInfo.tasks];
                updatedTasks[index].status = 'finished';
                await updateDoc(docRef, {
                    tasks: updatedTasks,
                });
            }
            toast.success(`${taskDetails.name} marked as finished`);
            // user gets XP   

            const docRef = doc(db, "teams", teamId);
            const updatedMembers = [...teamInfo.members];
            const memberIndex = updatedMembers.findIndex((member) => member.user === email);
            console.log(updatedMembers, memberIndex);
            updatedMembers[memberIndex].XP += taskDetails.xp;

            await updateDoc(docRef, {
                members: updatedMembers,
            });
        }

    }

    const joinChallenge = async (taskDetails) => {

        if (index !== undefined && index >= 0) {
            const docRef = doc(db, "teams", teamId);
            console.log(teamInfo.tasks[index].doesChallenge, index);
            const updatedTasks = [...teamInfo.tasks];
            updatedTasks[index].doesChallenge.push(email);
            await updateDoc(docRef, {
                tasks: updatedTasks,
            });
            toast.success(`Joined challenge ${taskDetails.name}`);
        }
    }


    const finishChallenge = async (taskDetails) => {
        //add XP to user
        const docRef = doc(db, "teams", teamId);
        const updatedMembers = [...teamInfo.members];
        const memberIndex = updatedMembers.findIndex((member) => member.user === email);
        console.log(updatedMembers, memberIndex);
        updatedMembers[memberIndex].XP += taskDetails.xp;

        // remove user from doesChallenge array
        const updatedTasks = [...teamInfo.tasks];
        const taskIndex = updatedTasks[index].doesChallenge.findIndex((member) => member === email);
        if (taskIndex >= 0) {
            updatedTasks[index].doesChallenge.splice(taskIndex, 1);
        }

        // add to didChallenge array
        updatedTasks[index].didChallenge.push(email);

        // if all members have finished the challenge, change status to finished
        if (updatedTasks[index].didChallenge.length >= teamInfo.members.length) {
            updatedTasks[index].status = 'finished';
            toast.success(`Challenge status changed to finished`);
        }

        await updateDoc(docRef, {
            members: updatedMembers,
            tasks: updatedTasks,
        });

        toast.success(`You earned ${parseInt(taskDetails.xp)} XP!`);

    }

    const getUserID = async () => {
        const usersInfoCollectionRef = collection(db, 'userInfo');
        const q = query(usersInfoCollectionRef, where('email', '==', email));

        try {
            const querySnapshot = await getDocs(q);
            const userInfoData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setUserId(userInfoData[0].id);
            setUserInfo(userInfoData[0]);

        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        getUserID();
        setUpdate(false);
    }, [update])

    return (
        <div>
            <Toaster />

            <dialog id="my_modal_99" className="modal">
                {taskDetails && (
                    <form method="dialog" className="modal-box" >
                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                        <h3 className="font-bold text-lg">{taskDetails.name}</h3>
                        <p className='mb-3 text-gray-600'>Description: {taskDetails.description}</p>
                        {taskDetails.taskMode === 'challenge' &&
                            <div>
                                <ChallengeMembers taskInfo={taskDetails} teamInfo={teamInfo} />
                            </div>
                        }
                        <div className='flex items-center'>

                            <img src={alarm} className='w-5 h-5 inline-block mr-2' />
                            <p className='inline-block'>{`Estimated time investment: ${taskDetails.timeInvestment}`}</p>
                        </div>

                        <p>❗ Priority: {taskDetails.priority}</p>

                        <div className='flex items-center mt-5'>
                            {taskDetails.taskMode === 'ticket' && taskDetails.status !== 'finished' && (
                                <>
                                    <button className='btn btn-primary' onClick={() => addToMyTasks(taskDetails)}>Add to my tasks</button>
                                    <button className='btn btn-secondary ml-3' onClick={() => markAsFinished(taskDetails)}>Mark as finished</button>
                                </>
                            )}
                            {taskDetails.taskMode === 'challenge' && (
                                taskDetails.doesChallenge.includes(email) ? (
                                    taskDetails.didChallenge.includes(email) ? (
                                        <button className='btn btn-disabled' disabled>Challenge finished</button>
                                    ) : (
                                        <button className='btn btn-primary' onClick={
                                            () => {
                                                finishChallenge(taskDetails);
                                            }
                                        }>Mark as finished</button>
                                    )
                                ) : (
                                    taskDetails.didChallenge.includes(email) ? (
                                        <button className='btn btn-disabled' disabled>Challenge finished</button>
                                    ) : (
                                        <button className='btn btn-secondary' onClick={() => joinChallenge(taskDetails)}>Join challenge</button>
                                    )
                                )
                            )}
                        </div>
                    </form>
                )}
            </dialog>
        </div >
    )
}
