import { useEffect, useState } from 'react'
import Nav from '../components/Nav'
import { collection, query, where, getDocs, doc, setDoc, addDoc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/init';
import addCircle from '../assets/add-circle-outline.svg';
import logIn from '../assets/log-in-outline.svg';
import { Link } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import Loading from '../components/Loading';

export default function Home() {

    const email = localStorage.getItem('email');
    const [userInfo, setUserInfo] = useState([]);
    const displayName = localStorage.getItem('displayName');
    const usersInfoCollectionRef = collection(db, 'userInfo');
    const teamsCollectionRef = collection(db, 'teams');

    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCompany, setShowCompany] = useState(false);
    const [createdTeam, setCreatedTeam] = useState("");
    const [joinCode, setJoinCode] = useState("");
    const [company, setCompany] = useState("");
    const [userInfoId, setUserInfoId] = useState("");
    const [teamDetails, setTeamDetails] = useState([]);
    const [description, setDescription] = useState("");
    const [closeJoinCode, setCloseJoinCode] = useState(false);


    function getGreeting() {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        let greeting;

        if (currentHour >= 5 && currentHour < 12) {
            greeting = 'Good morning';
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = 'Good afternoon';
        } else if (currentHour >= 18 && currentHour < 24) {
            greeting = 'Good evening';
        } else {
            greeting = 'Good night';
        }

        return greeting;
    }

    const greeting = getGreeting();



    // get all the teams that the user is a part of
    useEffect(() => {
        const getUsersInfo = async () => {
            const usersInfoCollectionRef = collection(db, 'userInfo');
            const q = query(usersInfoCollectionRef, where('email', '==', email));

            try {
                const querySnapshot = await getDocs(q);
                const userInfoData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

                if (userInfoData.length === 0) {
                    // If userInfo doesn't exist for the user, create a new document
                    const newUserInfo = {
                        email: email,
                        XP: 0,
                        about: '',
                        Teams: [],
                        myTasks: [],
                    };

                    // Add the new document to the collection and get the Document Reference
                    const docRef = await addDoc(usersInfoCollectionRef, newUserInfo);

                    // Set the userInfoId state to the newly created document's ID
                    setUserInfoId(docRef.id);

                    setTeams([]);
                    setLoading(false);
                } else {
                    // If userInfo exists, set the userInfo state to the existing document
                    setUserInfoId(userInfoData[0].id); // Set the userInfoId state to the existing document's ID

                    setTeams(userInfoData[0].Teams);

                }
            } catch (error) {
                console.error('Error fetching/creating user info:', error);
            }
        };

        getUsersInfo();
    }, [email]);

    useEffect(() => {
        document.title = `Home | collab hour`;
    }, []);

    // get the photoURL from the localStorage
    const photoURL = localStorage.getItem('photoUrl');

    const createTeam = async (createdTeam, email) => {
        try {
            // Create a new team object
            const newTeam = {
                name: createdTeam,
                members: [{
                    user: email,
                    XP: 0,
                    photoURL: photoURL,
                }],
                admin: email,
                company: company,
                description: description,
            };

            if (!userInfoId) {
                console.error("Error creating team: userInfoId is empty or not set correctly.");
                return;
            }

            // Add the new team to the teams collection and get the Document Reference
            const teamDocRef = await addDoc(teamsCollectionRef, newTeam);

            // Update the user's information with the new team ID
            await updateDoc(doc(usersInfoCollectionRef, userInfoId), {
                Teams: [...teams, teamDocRef.id], // Add the ID of the newly created team to the existing Teams array
            });

            // Update the teams state with the new team ID
            setTeams([...teams, teamDocRef.id]);

            console.log("Team created successfully!");
        } catch (error) {
            console.error("Error creating team: ", error);
        }
    };

    const getTeamDetails = async () => {
        try {
            const teamDetailsPromises = teams.map(async (teamId) => {
                const teamDocRef = doc(db, 'teams', teamId);
                const teamDocSnapshot = await getDoc(teamDocRef);

                if (teamDocSnapshot.exists()) {
                    // If the team document exists, return its data
                    return { id: teamDocSnapshot.id, ...teamDocSnapshot.data() };
                } else {
                    // If the team document doesn't exist (optional handling)
                    return null;
                }
            });

            // Wait for all the team details promises to resolve
            const teamDetails = await Promise.all(teamDetailsPromises);

            // Filter out any null values (optional handling if you chose to handle non-existing teams)
            const existingTeams = teamDetails.filter((team) => team !== null);


            setTeamDetails(existingTeams);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching team details:', error);
        }
    };

    useEffect(() => {
        if (teams.length > 0) {
            getTeamDetails();
        }
    }, [teams]);


    const joinTeam = async (joinCode) => {
        const docRef = doc(db, "teams", joinCode);
        const docSnap = await getDoc(docRef);

        if (teams.includes(joinCode)) {
            toast.error('You are already a part of this team.');
            return;
        }

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            await updateDoc(doc(usersInfoCollectionRef, userInfoId), {
                Teams: [...teams, joinCode], // Add the ID of the newly created team to the existing Teams array
            });
            await updateDoc(docRef, {
                members: [...docSnap.data().members, {
                    user: email,
                    XP: 0,
                    photoURL: photoURL,
                }],
            });


            // Update the teams state with the new team ID
            setTeams([...teams, joinCode]);
            setCloseJoinCode(true);
            console.log("Team joined successfully!");
            toast.success('Team joined successfully!');

        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            toast.error('No such team exist, please try again or contact your team admin.');
        }


    };


    return (

        <div>
            <Nav />
            <div><Toaster /></div>

            <div className='px-6 py-10'>
                <h1 className='text-blue-600 font-bold text-3xl mb-5'>{`${greeting}, ${displayName}`}</h1>
                {loading ? (
                    <Loading />
                ) : (
                    <div>

                        <div>
                            {teams.length < 1 && (
                                <h2 className='text-gray-500'>You are not a part of any teams yet</h2>
                            )}

                            <button className='btn btn-primary mt-5 md:mr-4 mr-2' onClick={() => window.my_modal_3.showModal()}>
                                <img src={addCircle} className="h-6 w-6" />
                                Create team
                            </button>
                            <dialog id="my_modal_3" className="modal">
                                <form method="dialog" className="modal-box">
                                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                    <h3 className="font-bold text-lg">Create a team</h3>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Team Name</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="e.g. software engineering"
                                            className="input input-bordered w-full"
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                setCreatedTeam(inputValue);
                                            }}
                                        />

                                    </div>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Description</span>
                                        </label>
                                        <textarea placeholder="e.g. building the future of project management 🚀 " onChange={
                                            (event) => {
                                                const inputValue = event.target.value;
                                                setDescription(inputValue);
                                            }
                                        }
                                            className="textarea textarea-bordered w-full" >
                                        </textarea>

                                    </div>
                                    <div className="flex mt-5">
                                        <input type="checkbox" className="checkbox" onChange={() => setShowCompany(!showCompany)} />
                                        <span className='ml-3'>Organization intern team</span>
                                    </div>
                                    {showCompany && (
                                        <div className="form-control w-full">
                                            <label className="label">
                                                <span className="label-text">Company name</span>
                                            </label>
                                            <input type="text" placeholder="e.g. powerhour" className="input input-bordered w-full"
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    setCompany(inputValue);
                                                }}
                                            />
                                        </div>
                                    )}
                                    <button className={`btn btn-block mt-5 ${(createdTeam.length == 0 && "btn-disabled")} btn-primary`} onClick={() => createTeam(createdTeam, email)}>create</button>
                                </form>
                            </dialog>

                            <button className='btn btn-secondary mt-2' onClick={() => window.my_modal_4.showModal()}>
                                <img src={logIn} className="h-6 w-6" />
                                Join team
                            </button>
                            <dialog id="my_modal_4" className="modal">
                                <form method="dialog" className="modal-box" onSubmit={() => {
                                    if (joinCode.length > 0) {
                                        joinTeam(joinCode)
                                    }
                                }}>
                                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                    <h3 className="font-bold text-lg">Join a team</h3>
                                    <div className="form-control w-full">
                                        <label className="label">
                                            <span className="label-text">Join Code</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="e.g. m8cKrL9F3x2JDHS9gdFu"
                                            className="input input-bordered w-full"
                                            onChange={(event) => {
                                                const inputValue = event.target.value;
                                                setJoinCode(inputValue);
                                            }}
                                        />

                                    </div>
                                    <button className={`btn btn-block mt-5 ${(joinCode.length == 0 && "btn-disabled")} btn-primary`} type='submit'>Join</button>
                                </form>
                            </dialog>
                        </div>



                        {
                            teams.length > 0 && (
                                <div className="mt-8">


                                    {teamDetails.map((team, i) => (
                                        <div className="hover:bg-base-200 flex justify-between py-5 border-base-300 border-2 border-solid rounded-lg my-2 px-2 items-center flex-wrap " key={team.id}>
                                            <div className='flex'>
                                                <p className='font-bold'>{team.name}</p>
                                                <div className='md:flex items-center hidden'>
                                                    <p className='mx-2'>•</p>
                                                    <p>{team.company ? team.company : "-"}</p>
                                                </div>
                                            </div>
                                            <div className='flex items-center'>
                                                <Link to={`/team/${team.id}`} className='btn btn-neutral mr-2'>Go to team</Link>
                                            </div>
                                        </div>
                                    ))}


                                </div>
                            )
                        }



                    </div>
                )}
            </div>
        </div>
    )
}