import React from 'react'
import { logout } from '../firebase/init';
import { Link } from 'react-router-dom';


export default function Nav() {

    const profilePic = localStorage.getItem('photoUrl');
    return (
        <div className="navbar bg-base-100">
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost btn-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                    </label>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        <li><Link to="/">Home</Link></li>
                    </ul>
                </div>
            </div>
            <div className="navbar-center">
                <Link className="btn btn-ghost normal-case flex flex-wrap items-center justify-center" to='/'>
                    <div className=''>
                        <h1 className='font-bold text-xl'>collab hour</h1>

                    </div>
                </Link>
            </div>
            <div className="navbar-end">
                <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                        <div className="w-10 rounded-full">
                            <img src={`${profilePic}`} referrerPolicy="no-referrer" />
                        </div>
                    </label>
                    <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                        <li>
                            <Link to="/profile" className="justify-between">
                                Profile
                            </Link>
                        </li>
                        <li>
                            <Link to="/mytasks" className="justify-between">
                                My tasks
                            </Link>
                        </li>
                        <li onClick={() => logout()}><a>Logout</a></li>
                    </ul>
                </div>
            </div>
        </div >
    )
}
