import React from 'react'
import LandingNav from '../components/LandingNav'
import RankingExample from '../assets/RankingExample.png'
import PrizesExample from '../assets/PrizesExample.png'
import TasksExample from '../assets/TasksExample.svg'
import LandingFooter from '../components/LandingFooter'
import { Link } from 'react-router-dom'


export default function Landing() {
    return (
        <div className='overflow-hidden'>
            <LandingNav />
            <div className='px-6 py-10 mt-24'>
                <div className='w-full flex items-center justify-center h-96 flex-wrap'>
                    <div>
                        <h1 className=' text-7xl text-center'>🎯</h1>
                        <h1 className='font-bold text-5xl text-base-content text-center w-full'>Have <span className='text-purple-400'>fun</span> working together.</h1>
                        <h2 className='text-xl text-center mt-3'>Turn your group projects into friendly competitions and reward your top performers.</h2>
                    </div>
                </div>

                <div className='md:grid md:grid-cols-2 md:mt-5 mt-24' id='features'>

                    <div className='flex justify-center'>
                        <div className='relative inline-block md:w-96 md:mr-5 w-full'>

                            <img src={PrizesExample} className=' object-contain rounded-md' />
                            <h1 className='absolute md:-top-8 -top-12 md:right-0 md:ml-3 md:text-7xl text-6xl right-1/2 translate-x-1/2 md:translate-x-0'>🏆</h1>

                        </div>
                    </div>
                    <div className='md:flex items-center justify-center hidden'>
                        <div>
                            <h1 className='text-3xl font-bold text-center md:w-96'>Motivate your top performers with cool prizes</h1>
                        </div>
                    </div>
                </div>
                <div className='md:grid md:grid-cols-2 md:mt-5 mt-24'>
                    <div className='flex items-center justify-center'>
                        <h1 className='text-3xl font-bold text-center mb-16 w-96'>Turn your projects into friendly competitions</h1>
                    </div>
                    <div className='flex justify-center'>
                        <img src={RankingExample} className='md:ml-10 h-96 object-contain' />
                    </div>
                </div>
                <div className='md:grid md:grid-cols-2 md:mt-10 mt-24'>
                    <div className='md:hidden items-center justify-center flex'>
                        <h1 className='text-3xl font-bold text-center mb-16 w-96'>Seamlessly track what needs to be done and what has been done</h1>
                    </div>
                    <div className='flex justify-center'>
                        <img src={TasksExample} className='md:ml-10' />
                    </div>
                    <div className='md:flex items-center justify-center hidden'>
                        <h1 className='text-3xl font-bold text-center mb-16 w-96'>Seamlessly track what needs to be done and what has been done</h1>
                    </div>
                </div>


                <div className="mb-20 mt-10" id="pricing">
                    <h1 className="w-full text-center text-4xl font-bold mb-5 text-base-content">Pricing</h1>
                    <p className="w-full text-center text-lg text-base-content mb-4">The current version is an open beta which is completely free to use without restrictions.</p>
                    <p className="w-full text-center text-lg text-base-content">Furthermore there will always be a free tier, enjoy! 😊</p>
                </div>
            </div>

            <div className='flex justify-center flex-wrap mb-28'>
                <h1 className='w-full text-center text-4xl font-bold mb-5'>Curious?</h1>
                <Link to="/login"><button className='btn btn-secondary btn-wide'> 👉 Open App</button></Link>
            </div>

            <LandingFooter />
        </div>
    )
}
