import React from 'react'

export default function TeamDetails({ TeamDetails }) {
    return (
        <div>
            <button className="btn ml-2" onClick={() => window.my_modal_2.showModal()}>
                <span className='block md:hidden'>•••</span>
                <span className='hidden md:block'>Team Details</span>
            </button>
            <dialog id="my_modal_2" className="modal">
                <form method="dialog" className="modal-box">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                    <h3 className="font-bold text-lg mb-3">{TeamDetails.name}</h3>
                    {TeamDetails.company && <h5>Organization: {TeamDetails.company}</h5>}
                    <h5 className='mt-1'>Team admin: {TeamDetails.admin}</h5>
                    <h5 className='mt-1'>Members: {TeamDetails.members.length}</h5>
                    <div className='rounded bg-base-200 p-2 mt-2'>
                        <p className='font-bold'>Description:</p>
                        <p >{TeamDetails.description ? TeamDetails.description : "No description provided"}</p>
                    </div>
                    <button className='btn btn-block btn-primary mt-5'>Close</button>
                </form>
            </dialog>
        </div>
    )
}
