import React from 'react'
import { Link } from 'react-router-dom'

export default function LandingNav() {
    return (
        <div className='px-3 fixed z-50 top-0 left-0 right-0'>
            <div className="navbar bg-base-100 ">
                <div className="flex-1">
                    <a className="btn btn-ghost normal-case text-xl">collabhour</a>
                </div>
                <div className="flex-none">
                    <ul className="menu menu-horizontal px-1">
                        <li><a href='#features' className='md:block hidden'>Features</a></li>
                        <li><a href='#pricing'>Pricing</a></li>
                    </ul>
                    <Link to="/login" className='btn btn-primary ml-2 text-sm'>Open App</Link>
                </div>
            </div>
        </div>
    )
}
